import throttle from 'lodash/throttle';

/**
 * When tab is re-activated, call a throttled function
 * @param  {Number}   wait     Number of seconds to throttle
 * @param  {Function} callback Function to call
 */
export default function onTabActive(wait, callback) {
  const throttled = throttle(callback, wait * 1000, { leading: true, trailing: false });

  const onVisibilityChange = () => {
    if (!document.hidden) {
      throttled();
    }
  };

  document.addEventListener('visibilitychange', onVisibilityChange, false);
}
