import React from 'react';
import PropTypes from 'prop-types';
import ceil from 'lodash/ceil';
import get from 'lodash/get';

import SpecialFormattedNumber from '../../containers/Utility/Price/SpecialFormattedNumber';

export default function CheckoutDiscount({ item }) {
  let totalSavingPercent = Math.abs(get(item, 'out.totalSavingPercent', 0));
  if (totalSavingPercent > 99) totalSavingPercent = 99;

  /* eslint-disable react/style-prop-object */
  if (item.type === 'bundle' && item.giveaway && !item.hideDiscount) {
    return (
      <div className="discount-container">
        -100%
      </div>
    );
  }

  if (item.pickAndMix && !item.hideDiscount) {
    const saving = item.out.price - item.out.discountPrice;
    const percentSaved = saving / item.out.price;
    if (percentSaved === 0) return false;
    return (
      <div className="discount-container">
        -<SpecialFormattedNumber value={ceil(percentSaved, 2)} style="percent" />
      </div>
    );
  }

  if (item.out && item.out.isStarDeal && !item.hideDiscount) {
    const saving = item.out.price - item.out.discountPrice;
    const percentSaved = saving / item.out.price;
    return (
      <div className="discount-container">
        -<SpecialFormattedNumber value={ceil(percentSaved, 2)} style="percent" />
      </div>
    );
  }

  if (totalSavingPercent > 0 && !item.hideDiscount) {
    return (
      <div className="discount-container">
        -{totalSavingPercent}%
      </div>
    );
  }

  if (
    item.type === 'bundle' &&
    item.current_discount &&
    item.current_discount.percent &&
    item.current_discount.percent > 0 &&
    !item.hideDiscount
  ) {
    let percent = item.current_discount.percent;
    if (percent === 1) percent = 0.99;
    return (
      <div className="discount-container">
        -<SpecialFormattedNumber value={percent} style="percent" />
      </div>
    );
  }

  if (
    item.current_discount &&
    item.current_discount.percent &&
    item.current_discount.percent > 0 &&
    !item.hideDiscount
  ) {
    return (
      <div className="discount-container">
        -<SpecialFormattedNumber value={item.current_discount.percent} style="percent" />
      </div>
    );
  }

  return false;
}

CheckoutDiscount.propTypes = {
  item: PropTypes.shape({}).isRequired,
};
