import env from '../../env';

/**
 * generates a recaptcha v3 token to be verified
 * @param  {String} action where the token generation was requested
 * @return {String}      generated v3 token
 */

export const GenerateV3Token = async (action) => {
  let v3RecaptchaToken;
  try {
    if (window.grecaptcha) {
      v3RecaptchaToken =
      await grecaptcha.execute(env.gcp.reCaptcha3PublicKey, { action });
    }
  } catch (ex) {
    console.error(ex); // eslint-disable-line no-console
    return '';
  }

  return v3RecaptchaToken;
};

export default GenerateV3Token;
