import { useEffect, useState } from 'react';

/**
 *  Calculate the time remaining until the countdown end date
 * @param {number} countDownEndDate
 * @returns  {Object} timeLeft in days, hours, minutes, seconds
 */
const calculateCountdown = (countDownEndDate) => {
  const timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  if (countDownEndDate > Date.now()) {
    const dealTimeLeft = (countDownEndDate - Date.now()) / 1000;
    const remainingMins = dealTimeLeft / 60;

    timeLeft.days = Math.floor(remainingMins / 1440);
    timeLeft.hours = Math.floor((remainingMins / 60) % 24);
    timeLeft.minutes = Math.floor(remainingMins % 60);
    timeLeft.seconds = Math.floor(dealTimeLeft % 60);
  }

  return timeLeft;
};

const useCountdownTimer = (countDownEndDate) => {
  const [time, setTime] = useState(calculateCountdown(countDownEndDate));

  useEffect(() => {
    let timer;
    if (countDownEndDate > Date.now()) {
      timer = setInterval(() => {
        setTime(calculateCountdown(countDownEndDate));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [countDownEndDate]);

  return time;
};

export default useCountdownTimer;
