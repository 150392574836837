import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { currencyCode } from '../../Helpers/Geo/data';
import SpecialFormattedNumber from '../../containers/Utility/Price/SpecialFormattedNumber';

export default function CheckoutPrice({ item, freeProduct }) {
  if (freeProduct) {
    return (
      <div className="price-container">
        <FormattedMessage id="checkout.checkout-price.free" defaultMessage="FREE" />
      </div>
    );
  }

  if (item.pickAndMix && item.pickAndMix.appliedDiscount > 0) {
    return (
      <div className="price-container">
        <SpecialFormattedNumber
          value={(item.pickAndMix.appliedDiscount) * item.quantity}
          style="currency"
        />
      </div>
    );
  }

  if (item.pickAndMix) {
    return (
      <div className="price-container">
        <SpecialFormattedNumber
          value={(item.pickAndMix.price) * item.quantity}
          style="currency"
        />
      </div>
    );
  }


  if (item.out && item.out.isStarDeal) {
    return (
      <div className="price-container">
        <SpecialFormattedNumber
          className="btn btn-primary"
          value={(item.out.discountPrice / 100) * item.quantity}
          style="currency"
        />
      </div>
    );
  }

  if (item.out && item.out.couponPrice > 0) {
    return (
      <div className="price-container">
        <SpecialFormattedNumber
          value={(item.out.couponPrice / 100) * item.quantity}
          style="currency"
        />
      </div>
    );
  }

  if (item.type === 'bundle' && !item.is_srp_bundle) {
    return (
      <div className="price-container">
        <SpecialFormattedNumber
          value={(item.price[currencyCode] / 100) * item.quantity}
          style="currency"
        />
      </div>
    );
  }

  return (
    <div className="price-container">
      <SpecialFormattedNumber
        value={(item.out.discountPrice / 100) * item.quantity}
        style="currency"
      />
    </div>
  );
}

CheckoutPrice.propTypes = {
  item: PropTypes.shape({}).isRequired,
  freeProduct: PropTypes.bool,
};

CheckoutPrice.defaultProps = {
  freeProduct: false,
};
