import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Tooltip({
  popoutIcon,
  content,
  tooltipClassName,
  children,
  hideTooltip,
  ariaLabel,
}) {
  const [visible, setVisibility] = useState(false);

  const updateVisibility = (event, visibleState) => {
    event.preventDefault();
    if (visibleState === 'click') {
      setVisibility(!visible);
    }
    if (visibleState !== 'click') {
      setVisibility(visibleState);
    }
  };

  const updateVisibilityOnKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setVisibility(!visible);
    }
  };

  return (
    <React.Fragment>
      <div
        aria-describedby="tooltip"
        aria-label={ariaLabel}
        role="button"
        aria-expanded={visible}
        tabIndex={-1}
        id="tooltip-left-icon"
        onMouseEnter={event => updateVisibility(event, true)}
        onMouseLeave={event => updateVisibility(event, false)}
        onClick={event => updateVisibility(event, 'click')}
        onKeyDown={updateVisibilityOnKeyDown}
      >
        <div aria-hidden>{children}</div>
      </div>
      {visible && !hideTooltip &&
        <React.Fragment>
          <div role="tooltip" id="tooltip" className={tooltipClassName}>
            <div className="arrow_box">
              <div className="tooltip-content">
                {popoutIcon &&
                  <div className="pl-2">
                    <FontAwesomeIcon icon={popoutIcon} />
                  </div>
                }
                <div className="pl-2 pr-2">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

Tooltip.propTypes = {
  popoutIcon: PropTypes.string,
  content: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  tooltipClassName: PropTypes.string,
  hideTooltip: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Tooltip.defaultProps = {
  popoutIcon: '',
  content: <React.Fragment />,
  children: [<React.Fragment />],
  tooltipClassName: '',
  hideTooltip: false,
  ariaLabel: '',
};
