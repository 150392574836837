import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ModalGroup = lazy(() => import(/* webpackChunkName: "ModalGroup" */'../../components/App/ModalGroup'));

function ModalGroupContainer({
  open,
}) {
  if (!open) { return false; }

  return (
    <Suspense fallback={<div />}>
      <ModalGroup open={open} />
    </Suspense>
  );
}

ModalGroupContainer.propTypes = {
  open: PropTypes.bool,
};

ModalGroupContainer.defaultProps = {
  open: false,
};

const mapStateToProps = ({
  modal,
}) => {
  const open = !!Object.keys(modal).find(modalName => modalName.includes('frontend/modal') && modal[modalName]);
  return { open };
};

export default connect(mapStateToProps)(ModalGroupContainer);
