import React from 'react';
import PropTypes from 'prop-types';
import floor from 'lodash/floor';
import { FormattedMessage } from 'react-intl';

import { currencyCode } from '../../../Helpers/Geo/data';
import SpecialFormattedNumber from './SpecialFormattedNumber';

function Price({
  tag: Tag,
  price,
  discount,
  type,
  bundlesTiered,
  pence: isPence,
  dontConvert,
  quantity,
  bonusDiscount,
  hideDecimals,
}) {
  if (!price || !Object.keys(price).length > 0) return false;
  if (!currencyCode) return false;
  const pence = price[currencyCode];

  let actualPrice = pence;
  if (type !== 'bundle' && discount > 0 && discount <= 1) {
    actualPrice *= 1 - discount;
  }

  if (isPence) actualPrice = floor(actualPrice / 100, 2);

  if (bonusDiscount > 0 && bonusDiscount <= 1) {
    actualPrice *= 1 - bonusDiscount;
  }

  return (
    <Tag>
      {bundlesTiered && <FormattedMessage id="price.from" defaultMessage="From" />}
      {/* eslint-disable react/style-prop-object */}
      <SpecialFormattedNumber value={actualPrice * quantity} dontConvert={dontConvert} style="currency" hideDecimals={hideDecimals} />
    </Tag>
  );
}

const priceProp = {
  GBP: PropTypes.number,
  EUR: PropTypes.number,
  USD: PropTypes.number,
  CAD: PropTypes.number,
  AUD: PropTypes.number,
  RUB: PropTypes.number,
  JPY: PropTypes.number,
};

Price.propTypes = {
  price: PropTypes.shape(priceProp),
  dontConvert: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  discount: PropTypes.number,
  quantity: PropTypes.number,
  type: PropTypes.oneOf([
    'game',
    'bundle',
    'dlc',
    'book',
    'comic',
  ]),
  bundlesTiered: PropTypes.bool,
  pence: PropTypes.bool,
  hideDecimals: PropTypes.bool,
  bonusDiscount: PropTypes.number,
};

Price.defaultProps = {
  price: priceProp.GBP,
  tag: 'span',
  discount: null,
  quantity: 1,
  dontConvert: false,
  type: 'game',
  pence: true,
  fullPrice: {},
  bundlesTiered: false,
  bonusDiscount: null,
  hideDecimals: false,
};

export default Price;
