import get from 'lodash/get';

import store from '../redux/store';
import cartCalcSelector from '../reselects/cartcalc';

/**
 * Helper functions for dealing with redux state
 * Only functions that interact with the current state should be added here
 * @type {Object}
 */

const State = {};

State.getState = (type) => {
  const state = store.getState();
  if (type) {
    return get(state, type, {});
  }
  return state;
};

State.getAnonId = () => {
  const anonymous = State.getState('anonymous');
  return get(anonymous, 'id');
};

State.getUserId = () => {
  const auth = State.getState('auth');
  return get(auth, '_id') || State.getAnonId();
};

State.getPageId = () => {
  const router = State.getState('router');
  return get(router, 'location.pathname');
};

State.getCartItems = () => {
  const cart = cartCalcSelector(State.getState());
  return get(cart, 'items', []);
};

State.getCartItemsString = () => {
  const items = State.getCartItems();
  return items.map(cartItem => cartItem._id).toString();
};

State.getGiftOrderData = () => {
  const gift = State.getState('gift');
  return get(gift, 'giftOrder');
};

export default State;
