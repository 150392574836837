/**
 * search current url for a given query string
 * @param  {String} name query string identifier
 * @param  {String} url overriden url to search
 * @return {String}      query string value
 */
const getUrlParameter = (name, url) => {
  const escapedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&#]${escapedName}=([^&#]*)`);
  const results = regex.exec(url || window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default getUrlParameter;
