import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import getTrustPilotLogo from '../../../Helpers/Image/TrustPilotLogo';
import './TrustpilotStrip.scss';

function TrustpilotStrip({ trustpilot, logoColor, minimal }) {
  return (
    <div className="trustpilotstrip">
      <div className="logo-container">
        <a
          href="https://www.trustpilot.com/review/www.fanatical.com"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={-1}
        >
          <img
            className="trust-pilot-img"
            src={getTrustPilotLogo(logoColor).image}
            alt={getTrustPilotLogo(logoColor).alt}
          />
        </a>
      </div>
      {!minimal &&
        <div className="message-container">
          <a
            href="https://www.trustpilot.com/review/www.fanatical.com"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={-1}
          >
            <FormattedMessage
              id="usp-bar.point.trustpilot"
              defaultMessage="Rated 'EXCELLENT' Based on {count} Reviews"
              values={{
                count: <FormattedNumber value={trustpilot.total} />,
              }}
            />
          </a>
        </div>
      }
    </div>
  );
}

TrustpilotStrip.propTypes = {
  trustpilot: PropTypes.shape({}),
  logoColor: PropTypes.string,
  minimal: PropTypes.bool,
};

TrustpilotStrip.defaultProps = {
  trustpilot: 0,
  logoColor: 'black',
  minimal: false,
};

const mapStateToProps = ({
  trustpilot,
}) => ({
  trustpilot,
});
export default connect(mapStateToProps, null)(TrustpilotStrip);
