import get from 'lodash/get';

import env from '../../../env';
import { currencyCode } from '../../../Helpers/Geo/data';
import isPrerender from '../../../Helpers/SEO/is-prerender';

export default function hitToListItem(h, i, noLinks) {
  const hitUrl = h.link || `${env.appProtocol}://${env.appUrl}/${window.locale || 'en'}/${h.type}/${h.slug}`;

  let availability = 'InStock';

  if (h.release_date && new Date(h.release_date * 1000) > new Date()) {
    availability = 'PreOrder';
  }

  return {
    '@type': 'ListItem',
    position: i + 1,
    name: h.name,
    item: env.enhanceItemLists ? {
      '@id': `${hitUrl}#product`,
      '@type': noLinks ? 'VideoGame' : 'Product',
      name: h.name,
      url: noLinks ? undefined : hitUrl,
      mainEntityOfPage: noLinks ? undefined : {
        '@id': `${hitUrl}#webpage`,
        '@type': 'ItemPage',
      },
      image: `${env.cdnExtUrl}/product/1280x720/${h.cover}`,
      description: get(h, `sdescs.${window.locale}`, undefined),
      releaseDate: typeof h.release_date === 'number' ? new Date(h.release_date * 1000).toISOString() : h.release_date || undefined,
      sku: h.product_id || h._id || undefined,

      // If we want to turn back on multiple currency offers
      // offers: noLinks ? undefined : Object.keys(product.price).map((code) => {
      offers: noLinks ? undefined : [isPrerender() ? 'USD' : currencyCode].map((code) => {
        const offer = {
          '@type': 'Offer',
          price: get(h, `price.${code}`, 0),
          priceCurrency: code,
          availability,
          priceValidUntil: new Date(Date.now() + (1000 * 60 * 60 * 48)).toISOString(), // default
          url: `https://${env.appUrl}/${window.locale || 'en'}/${h.type || 'game'}/${h.slug}`,
        };

        if (h.discount_percent) {
          offer.priceValidUntil = new Date(h.available_valid_until * 1000).toISOString();
        }

        if (h.tiered) {
          offer['@type'] = 'AggregateOffer';
          offer.lowPrice = offer.price;
          offer.price = undefined;
        }

        return offer;
      }),

      // Turn off reviews unless they are needed because there's no offers?
      aggregateRating: h.rating_score && noLinks ? {
        '@type': 'AggregateRating',
        ratingValue: h.rating_score,
        ratingCount: h.total_ratings,
        bestRating: 5,
        worstRating: 1,
        description: 'User Reviews',
      } : undefined,
    } : undefined,
    url: env.enhanceItemLists ? undefined : hitUrl,
  };
}
