import slugify from 'slugify';

slugify.extend({
  '©': '',
  '®': '',
  '™': '',
});

export const encodeSlug = (str) => { // eslint-disable-line import/prefer-default-export
  try {
    return slugify(decodeURIComponent((str || '').replace(/_/g, ' ')), { strict: true, lower: true });
  } catch (e) {
    if (e.message && e.message.includes('URI malformed')) {
      if (str && str.includes && str.includes('%')) {
        // try again without %
        return encodeSlug(str.replace(/%/g, ''));
      }

      return 'URI malformed';
    }

    throw new Error(`Slugify error converting ${typeof str} '${str}' - ${e.message}`, e);
  }
};
