import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

function Drm({ drmType, colorOverride, siteTheme, iconSize, className }) {
  let iconColor = 'light';
  if (colorOverride) iconColor = colorOverride;
  if (!colorOverride && siteTheme === 'light') iconColor = 'dark';

  let iconName = drmType;
  if (drmType === 'uplay') {
    iconName = 'ubisoftconnect';
  }
  if (drmType === 'roblox') {
    iconName = 'roblox-2022';
  }
  if (drmType === 'eaplay') {
    iconName = 'ea';
  }
  if (drmType === 'oculus') {
    iconName = 'metaquest';
  }
  if (drmType === 'razer') {
    iconName = 'razer-horizontal';
  }

  if (
    drmType === '' ||
    drmType === 'drm_free' ||
    drmType === 'drm free' ||
    drmType === 'redeem' ||
    drmType === 'coupon' ||
    drmType === 'voucher'
  ) {
    return false;
  }

  return (
    <div className={classNames('drm-container', `drm-container-${drmType}`, { large: iconSize === 'large' }, { small: iconSize === 'small' }, `${className}`)}>
      <img
        src={`https://cdn.fanatical.com/production/icons/drm/${iconColor}/${iconName}.svg`}
        alt={`${iconName} logo`}
        width="100%"
        height="100%"
      />
    </div>
  );
}

Drm.propTypes = {
  drmType: PropTypes.oneOf([
    '',
    'steam',
    'steam-icon',
    'uplay',
    'rockstar',
    'esonline',
    'bethesda',
    'oculus',
    'epicgames',
    'switch',
    'threeds',
    'origin',
    'gog',
    'magix',
    'zenva',
    'utalk',
    'drm_free',
    'drm free',
    'redeem',
    'coupon',
    'voucher',
    'playstation',
    'xbox',
    'roblox',
    'eaplay',
    'runescape',
    'razer',
    'microsoft',
    'drivethrurpg',
  ]),
  colorOverride: PropTypes.oneOf([
    '',
    'light',
    'dark',
  ]),
  iconSize: PropTypes.oneOf([
    '',
    'small',
    'large',
  ]),
  siteTheme: PropTypes.string,
  className: PropTypes.string,
};

Drm.defaultProps = {
  drmType: '',
  colorOverride: '',
  siteTheme: 'dark',
  iconSize: '',
  className: '',
};

const mapStateToProps = ({
  siteTheme,
}) => ({
  siteTheme,
});

export default connect(mapStateToProps)(Drm);
