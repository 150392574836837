/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'smartProductRecommendations', // Test 383
  'CartUndoWithDelay', // Test 386
  'DesktopEditionsPosition', // Test 388
  'HomePageUserRecommendations', // Test 391
  'smartCartRecommendationsV2', // Test 392
  'MegaMenuCategoriesTab', // 393
  'PickAndMixMicroTrailersV2', // Test 394
  'ProductPageMobileRelatedProducts', // Test 395
];
