import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function BrandGamespot() {
  return (
    <div className="navbar-brand NavBar__brandContainer NavBar__brandContainer--gamespot">
      <Link to="/" className="NavBar__brandLogo">
        <img src="https://cdn.fanatical.com/production/brand/fanatical-logo-black.svg" alt="Fanatical" />
      </Link>
      <div className="NavBar__brandAssociation">
        <FormattedMessage id="brand-gamespot.curated-for" defaultMessage="Curated for" />
        <a href="https://www.gamespot.com/" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn.fanatical.com/production/brand/co-brands/gamespot-logo-black.svg" alt="Gamespot" className="NavBar__brandAssociation__image" />
        </a>
      </div>
    </div>
  );
}
