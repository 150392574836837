const env = require('../../env');

const translationObject = {
  'Age-Rating-System': 'ageRatingBoard',
  'Currency-Alphabetic-Code': 'currencyCode',
  'Tax-Rate': 'taxRate',
  'Native-Currency-Alphabetic-Code': 'nativeCurrencyCode',
  'Native-Currency-Name': 'nativeCurrencyName',
  'Country-Name': 'countryName',
  'Country-Code': 'countryCode',
  gdprCompliant: 'gdprCompliant',
  xboxAvailable: 'xboxAvailable',
  'Currency-Name': 'currencyName',
};

const xboxIncluded = [
  'US',
  'GB',
];

const gdprIncluded = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'CA',
];

function generateCountryData(data) {
  const returnObject = {};

  Object.keys(data || {}).forEach((key) => {
    returnObject[translationObject[key]] = data[key];
  });

  returnObject.gdprCompliant = gdprIncluded.includes(data['Country-Code']);
  returnObject.xboxAvailable = xboxIncluded.includes(data['Country-Code']);

  return returnObject;
}

const countryData = generateCountryData(window.countryData || {
  'Country-Code': 'GB',
  'Currency-Alphabetic-Code': 'GBP',
  'Currency-Name': 'Pound Sterling',
  'Age-Rating-System': 'PEGI',
  'Tax-Rate': '20',
  'Country-Name': 'United Kingdom',
  'Native-Currency-Alphabetic-Code': null,
  'Native-Currency-Name': null,
});

if (env.isDevelopment) {
  const cc = new URL(window.location.href).searchParams.get('cc');
  if (cc) {
    countryData.countryCode = cc.toUpperCase();
  }
}

module.exports = countryData;
