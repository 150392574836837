import debug from './debug';

// Returns a promise that can be resolved externally
// https://stackoverflow.com/a/72539883/1868365
class DeferredPromise {
  constructor() {
    return new Proxy(new Promise((resolve, reject) => { // eslint-disable-line no-constructor-return
      this.resolve = resolve;
      this.reject = reject;
    }), {
      get: (target, prop) =>
        this[prop] || target[prop].bind(target),
    });
  }
}

/**
 * Create a new queue instance, that throttles and queues function calls
 * @param  {Function} processFunc Function to be throttled
 * @param  {Number} interval      Minimum interval in seconds
 * @return {Function}             Function to add jobs to the queue, arguments are passed through
 *                                to processFunc, and a promise is returned, which resolves when
 *                                the function has been run
 */
export default (processFunc, interval) => {
  const stack = []; // List of jobs
  let timer = null; // Replacable interval instance
  let id = 1; // Incremental job id

  // Function for processing queue, triggered in setInterval or setTimeout
  const process = async () => {
    const job = stack.shift();
    if (!job) {
      // Shouldn't get here, but just in case
      debug('Cancelling timer because no job to process');
      clearInterval(timer);
      return false;
    }

    debug('Processing Job', job.id);

    processFunc(...job.data)
      .then((result) => {
        debug('Processed Job', job.id);
        job.promise.resolve(result);
      })
      .catch(err => job.promise.reject(err));

    // If no jobs left, stop the interval
    // Timeout to stop queue being closed early
    setTimeout(() => {
      if (stack.length === 0) {
        debug('Clearing timer after job', job.id);
        clearInterval(timer);
        timer = null;
      }
    }, interval * 0.9);

    return null;
  };

  // Function for adding jobs to queue
  const add = (...args) => {
    const promise = new DeferredPromise();
    const job = { data: args, promise, id };
    debug('Queueing Job', job.id);
    stack.push(job);

    id += 1;

    if (timer === null) {
      debug('Starting new timer ', job.id);
      // If first job, process immediately
      setTimeout(process, 1); // Slight timeout to allow parallel jobs to stack and stop queue clearing
      timer = setInterval(process, interval);
    }

    return promise;
  };

  return add;
};
