import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const VisibleVoucher = lazy(() => import(/* webpackChunkName: "VisibleVoucher" */'./VisibleVoucher'));

const visibleVoucherExcludedPaths = ['/game/', '/bundle/', '/dlc/', '/pick-and-mix/', '/account', '/payment', '/receipt', '/publishers/nis-america-inc'];

const visibleVoucherOverridePaths = ['/bundle', '/bundle/games', '/bundle/books', '/bundle/software'];

function VisibleVoucherContainer({ showVisibleVoucher }) {
  if (showVisibleVoucher) {
    return (
      <Suspense fallback={<div />}>
        <VisibleVoucher/>
      </Suspense>
    );
  }

  return false;
}

VisibleVoucherContainer.propTypes = {
  showVisibleVoucher: PropTypes.bool,
};

VisibleVoucherContainer.defaultProps = {
  showVisibleVoucher: false,
};

const mapStateToProps = ({
  router: { location: { pathname } },
  vouchers: { all: vouchersAll },
}) => ({
  showVisibleVoucher: !!(vouchersAll || []).filter(v => v.always_visible).length && (
    visibleVoucherOverridePaths.includes(pathname) ||
    !visibleVoucherExcludedPaths.find(p => pathname.startsWith(p))
  ),
});

export default connect(mapStateToProps)(VisibleVoucherContainer);
