import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import env from '../../../env';
import { getAllSeo } from '../../../config/lang';
import NoIndex from './NoIndex';

const allowedCodes = getAllSeo().map(lang => lang.code);

export default function AlternativeMetaTags({ path }) {
  if (typeof path === 'undefined') {
    return false;
  }

  const cleanPath = path.replace('-?', '?').replace(/-$/, '');

  return (
    <React.Fragment>
      <Helmet>
        <link rel="alternate" hrefLang="x-default" href={`${env.appProtocol}://${env.appUrl}/en/${cleanPath}`} />
        {getAllSeo().map(lang => <link rel="alternate" key={lang.code} hrefLang={lang.code} href={`${env.appProtocol}://${env.appUrl}/${lang.code}/${cleanPath}`} />)}
      </Helmet>
      {(!allowedCodes.includes(window.locale)) &&
        <NoIndex noFollow />
      }
    </React.Fragment>
  );
}

AlternativeMetaTags.propTypes = {
  path: PropTypes.string.isRequired,
};
