/**
 * Formats a product name, appends dlc if product is type dlc;
 * @param  {String} name product name
 * @param  {String} type product type
 * @return {String}      return formatted name with dlc appended if required
 */

const formatProductName = (name, type) => {
  if (!name) return '';
  if (!type) return name;

  let nameToFormat = name;

  if (type === 'dlc' && (!nameToFormat.includes('DLC') && !nameToFormat.includes('dlc') && !nameToFormat.includes('Dlc'))) {
    nameToFormat = name.concat(' - DLC');
  }

  return nameToFormat;
};

export default formatProductName;
