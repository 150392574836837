import React, { useEffect } from 'react';


const GoogleOneTap = () => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof google !== 'undefined' && google.accounts) {
        google.accounts.id.prompt((notification) => {
          if (notification.isSkippedMoment()) {
            localStorage.setItem('dismissedOneTap', true);
          }
        });
      }
    }, 50);
  });

  return (
    <div className="google-prompted-login">
      <div
        id="g_id_onload"
        data-prompt_parent_id="g_id_onload"
        data-cancel_on_tap_outside="false"
        data-state_cookie_domain="fanatical.com"
        style={{ position: 'absolute', bottom: '10px', right: 0, height: 0, zIndex: 1001 }}
      />
    </div>
  );
};

export default GoogleOneTap;
