/**
 * Wrap a funtion with this to safely call new relic browser agent functions
 * If it is not available, the function won't get called
 *
 * ie. nrWrap(newrelic => newrelic.setCustomAttribute(key, value));
 */
const nrWrap = (func) => {
  if (!window.newrelic) {
    return false;
  }
  try {
    return func(window.newrelic);
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    return false;
  }
};

export default nrWrap;
