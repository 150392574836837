import get from 'lodash/get';

import store from '../../redux/store';

const getTrustPilotLogo = (type) => {
  const reduxStore = store.getState();
  const { trustpilot, siteTheme } = reduxStore;

  let image = 'https://cdn.fanatical.com/production/brand/trustpilot/svg/trustpilot-white-5.svg';
  let alt = 'Trustpilot 5 stars';

  if (get(trustpilot, 'score.stars', 0) === 4.5) {
    image = 'https://cdn.fanatical.com/production/brand/trustpilot/svg/trustpilot-white-4half.svg';
    alt = 'Trustpilot 4.5 stars';
  }

  if (siteTheme === 'light' || type === 'black') {
    image = 'https://cdn.fanatical.com/production/brand/trustpilot/svg/trustpilot-black-5.svg';
    if (get(trustpilot, 'score.stars', 0) === 4.5) {
      image = 'https://cdn.fanatical.com/production/brand/trustpilot/svg/trustpilot-black-4half.svg';
    }
  }

  return { image, alt };
};

export default getTrustPilotLogo;
