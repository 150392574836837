import React from 'react';
import PropTypes from 'prop-types';

const animation = (<div className="infinite-loader" id="infinite-loader" key={0}>
  <div className="spinner">
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
</div>);

export default function Loading({ text, fullHeight }) {
  return <div className={`loading-placeholder ${fullHeight ? 'fullHeight' : ''}`} id="loading-placeholder">{text || animation}</div>;
}

Loading.propTypes = {
  text: PropTypes.string,
  fullHeight: PropTypes.bool,
};

Loading.defaultProps = {
  text: '',
  fullHeight: false,
};
