const mostGiftedSlugs = [
  'hogwarts-legacy-digital-deluxe-edition',
  'elden-ring',
  'total-war-warhammer-iii',
  'hogwarts-legacy',
  'football-manager-2023',
  'persona-5-royal',
  'rimworld',
  'destiny-2-lightfall-annual-pass',
  'street-fighter-6',
  'god-of-war',
  'star-wars-collection',
  'street-fighter-6-ultimate-edition',
  'resident-evil-4',
  'street-fighter-6-deluxe-edition',
  'sonic-frontiers',
  'marvel-s-spider-man-miles-morales',
  'age-of-wonders-4-premium-edition',
  'return-to-monkey-island',
  'tiny-tina-s-wonderlands-chaotic-great-edition',
  'rimworld-with-all-expansions',
  'marvel-s-spider-man-remastered',
  'destiny-2-lightfall',
  'wo-long-fallen-dynasty-digital-deluxe-edition',
  'total-war-warhammer-ii',
  'the-last-of-us-part-i',
  'cult-of-the-lamb',
  'dying-light-2-stay-human',
  'rimworld-biotech',
  'starfield-premium-edition',
  'monster-hunter-rise',
  'sonic-frontiers-deluxe-edition',
  'tiny-tina-s-wonderlands',
  'core-keeper',
  'plateup',
  'age-of-wonders-4',
  'sniper-elite-5-deluxe-edition',
  'hell-let-loose',
  'sid-meier-s-civilization-vi-anthology',
  'total-war-warhammer-iii-champions-of-chaos',
  'no-man-s-sky',
  'monster-hunter-rise-sunbreak',
  'total-war-warhammer',
  'elden-ring-deluxe-edition',
  'marauders',
  'total-war-warhammer-iii-forge-of-the-chaos-dwarfs',
  'batman-arkham-collection',
  'gotham-knights-deluxe',
  'ready-or-not',
  'two-point-campus',
  'rim-world-ideology',
  'sniper-elite-5',
];

export default mostGiftedSlugs;
