import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function BrandAcer() {
  return (
    <div className="navbar-brand NavBar__brandContainer NavBar__brandContainer--acer">
      <Link to="/" className="NavBar__brandLogo">
        <img src="https://cdn.fanatical.com/production/brand/fanatical-logo-black.svg" alt="Fanatical" />
      </Link>
      <div className="NavBar__brandAssociation">
        <FormattedMessage id="brand-acer.curated-for" defaultMessage="Curated for" />
        <img src="https://cdn.fanatical.com/production/brand/co-brands/acer-logo.svg" alt="Acer" className="NavBar__brandAssociation__image" />
      </div>
    </div>
  );
}
