const fullPriceGameExclusions = [
  'cladun-x-2',
  'clan-of-champions',
  'clan-of-champions-three-eyed-deitys-aegis-1',
  'clan-of-champions-character-slot-dlc',
  'clan-of-champions-item-box',
  'clan-of-champions-new-helmet-pack-1',
  'clan-of-champions-new-shield-pack-1',
  'clan-of-champions-new-armor-pack-1',
  'clan-of-champions-gem-pack-1',
  'clan-of-champions-collection',
  'hyperdimension-neptunia-rebirth-1-complete-pack',
  'hyperdimension-neptunia-rebirth-2-complete-pack',
  'hyperdimension-neptunia-combo-pack',
  'disgaea-pc',
  'disgaea-pc-game',
  'disgaea-pc-digital-dood-edition',
  'disgaea-pc-digital-art-book',
  'hyperdevotion-noire-dlc-collection',
  'stranger-of-sword-city',
  'phantom-brave-pc',
  'phantom-brave-pc-game',
  'yomawari-night-alone',
  'yomawari-night-alone-pitch-dark-edition',
  'hto-l-ni-q-the-firefly-diary',
  'criminal-girls-invite-only',
  'criminal-girls-invite-only-digital-art-book',
  'criminal-girls-invite-only-digital-soundtrack',
  'criminal-girls-invite-only-digital-vip-edition',
  'disgaea-2-pc',
  'disgaea-2-pc-digital-art-book',
  'disgaea-2-pc-digital-dood-edition',
  'disgaea-pc-plus-disgaea-2-pc-digital-doods-edition',
  'operation-abyss-new-tokyo-legacy',
  'operation-abyss-new-tokyo-legacy-digital-soundtrack',
  'operation-abyss-new-tokyo-legacy-digital-art-book',
  'operation-abyss-new-tokyo-legacy-digital-limited-edition',
  'a-rose-in-the-twilight',
  'a-rose-in-the-twilight-digital-art-book',
  'a-rose-in-the-twilight-digital-soundtrack',
  'a-rose-in-the-twilight-digital-bloodlust-edition',
  'a-rose-in-the-twilight-htolniq-the-firefly-diary-digital-limited-edition',
  'psycho-pass-mandatory-happiness',
  'psycho-pass-mandatory-happiness-digital-art-book',
  'psycho-pass-mandatory-happiness-digital-alpha-edition-off',
  'birthdays-the-beginning',
  'birthdays-the-beginning-digital-art-book',
  'birthdays-the-beginning-digital-soundtrack',
  'birthdays-the-beginning-digital-limited-edition',
  'operation-babel-new-tokyo-legacy',
  'operation-babel-new-tokyo-legacy-digital-art-book-dlc',
  'operation-babel-new-tokyo-legacy-digital-soundtrack-dlc',
  'operation-babel-new-tokyo-legacy-digital-limited-edition',
  'operation-abyss-babel-new-tokyo-legacy-digital-limited-edition',
  'cladun-returns-this-is-sengoku',
  'raiden-v-directors-cut',
  'yomawari-midnight-shadows',
  'tokyo-tattoo-girls',
  'tokyo-tattoo-girls-digital-limited-edition',
  'tokyo-tattoo-girls-soundtrack',
  'tokyo-tattoo-girls-artbook',
  'injustice-2-raiden',
  'ys-viii-lacrimosa-of-dana-viii-lacrimosa-of-dana',
  'the-longest-five-minutes',
  'the-longest-five-minutes-digital-art-booklet',
  'the-longest-five-minutes-digital-soundtrack',
  'the-longest-five-minutes-digital-limited-edition',
  'the-25-th-ward-the-silver-case',
  'the-25-th-ward-the-silver-case-digital-limited-edition',
  'the-25-th-ward-the-silver-case-digital-soundtrack',
  'the-25-th-ward-the-silver-case-digital-artbook',
  'ys-viii-lacrimosa-of-dana-advanced-accessory-set',
  'ys-viii-lacrimosa-of-dana-bottled-potion-set',
  'ys-viii-lacrimosa-of-dana-castaway-start-dash-set',
  'ys-viii-lacrimosa-of-dana-economy-ingredient-set',
  'ys-viii-lacrimosa-of-dana-elixir-set-1',
  'ys-viii-lacrimosa-of-dana-elixir-set-2',
  'ys-viii-lacrimosa-of-dana-elixir-set-3',
  'ys-viii-lacrimosa-of-dana-elixir-set-4',
  'ys-viii-lacrimosa-of-dana-elixir-set-5',
  'ys-viii-lacrimosa-of-dana-fish-bait-set-1',
  'ys-viii-lacrimosa-of-dana-fish-bait-set-2',
  'ys-viii-lacrimosa-of-dana-fish-bait-set-3',
  'ys-viii-lacrimosa-of-dana-premium-material-set',
  'ys-viii-lacrimosa-of-dana-ripe-fruit-set',
  'ys-viii-lacrimosa-of-dana-status-recovery-set',
  'ys-viii-lacrimosa-of-dana-tempest-set-1',
  'ys-viii-lacrimosa-of-dana-tempest-set-2',
  'ys-viii-lacrimosa-of-dana-tempest-set-3',
  'ys-viii-lacrimosa-of-dana-tempest-set-4',
  'ys-viii-lacrimosa-of-dana-tempest-set-5',
  'ys-viii-lacrimosa-of-dana-useful-accessory-set',
  'ys-viii-lacrimosa-of-dana-ultimate-resource-bundle',
  'disgaea-5-complete',
  'disgaea-5-complete-digital-art-book',
  'disgaea-5-complete-digital-dood-edition',
  'labyrinth-of-refrain-coven-of-dusk',
  'labyrinth-of-refrain-coven-of-dusk-digital-limited-edition',
  'labyrinth-of-refrain-coven-of-dusk-digital-art-book',
  'labyrinth-of-refrain-coven-of-dusk-meel-s-best-bell',
  'labyrinth-of-refrain-coven-of-dusk-meel-s-best-earring',
  'labyrinth-of-refrain-coven-of-dusk-meel-s-best-shield',
  'labyrinth-of-refrain-coven-of-dusk-meel-s-manania-pact',
  'labyrinth-of-refrain-coven-of-dusk-meel-s-strategy-guide-pact',
  'killer-7',
  'assault-spy',
  'assault-spy-digital-soundtrack',
  'assault-spy-elite-spy-edition-bundle',
  'assault-spy-elite-spy-edition',
  'killer-7-2018-remastered-original-soundtrack',
  'killer-7-digital-art-booklet',
  'killer-7-difital-limited-edition',
  'the-caligula-effect-overdose',
  'the-caligula-effect-overdose-digital-soundtrack',
  'the-caligula-effect-overdose-digital-artbook',
  'the-caligula-effect-overdose-casual-clothes-costume',
  'the-caligula-effect-overdose-male-protagonist-swimsuit-costume',
  'the-caligula-effect-overdose-female-protagonist-swimsuit-costume',
  'the-caligula-effect-overdose-shogos-swimsuit-costume',
  'the-caligula-effect-overdose-izuris-swimsuit-costume',
  'the-caligula-effect-overdose-kotaros-swimsuit-costume',
  'the-caligula-effect-overdose-kensukes-swimsuit-costume',
  'the-caligula-effect-overdose-kotonos-swimsuit-costume',
  'the-caligula-effect-overdose-narukos-swimsuit-costume',
  'the-caligula-effect-overdose-mifues-swimsuit-costume',
  'the-caligula-effect-overdose-suzanas-swimsuit-costume',
  'the-caligula-effect-overdose-eijis-swimsuit-costume',
  'the-caligula-effect-overdose-ayanas-swimsuit-costume',
  'the-caligula-effect-overdose-swimsuit-bundle',
  'the-caligula-effect-overdose-digital-limited-edition',
  'raiden-legacy-steam-edition',
  'the-alliance-alive-hd-remastered',
  'dead-by-daylight',
  'the-alliance-alive-hd-remastered-digital-limited-edition',
  'psycho-pass-mandatory-happiness-digital-alpha-edition',
  'langrisser-i-ii',
  'langrisser-i-ii-digital-limited-edition',
  'langrisser-i-ii-visual-book',
  'langrisser-i-ii-original-2-disc-soundtrack',
  'langrisser-i-ii-songs-of-war-3-disc-soundtrack',
  'langrisser-i-ii-legacy-bgm-pack',
  'the-legend-of-heroes-trails-of-cold-steel-iii',
  'the-legend-of-heroes-trails-of-cold-steel-iii-digital-limited-edition',
  'the-legend-of-heroes-trails-of-cold-steel-iii-standard-cosmetic-set',
  'the-legend-of-heroes-trails-of-cold-steel-iii-consumable-starter-set',
  'the-legend-of-heroes-trails-of-cold-steel-iii-consumable-value-set',
  'the-legend-of-heroes-trails-of-cold-steel-iii-premium-cosmetic-set',
  'disaster-report-4-summer-memories',
  'disaster-report-4-summer-memories-costume-bundle',
  'disaster-report-4-summer-memories-digital-limited-edition',
  'disgaea-4-complete',
  'disgaea-4-complete-digital-dood-edition',
  'disgaea-4-complete-digital-art-book',
  'saviors-of-sapphire-wings-stranger-of-sword-city-revisited',
  'saviors-of-sapphire-wings-stranger-of-sword-city-revisited-digital-limited-edition',
  'saviors-of-sapphire-wings-stranger-of-sword-city-revisited-official-soundtrack',
  'saviors-of-sapphire-wings-stranger-of-sword-city-revisited-art-book',
  'the-legend-of-heroes-trails-of-cold-steel-iv-consumable-starter-set',
  'the-legend-of-heroes-trails-of-cold-steel-iv-consumable-value-set',
  'the-legend-of-heroes-trails-of-cold-steel-iv-standard-cosmetic-set',
  'the-legend-of-heroes-trails-of-cold-steel-iv-premium-cosmetic-set',
  'the-legend-of-heroes-trails-of-cold-steel-iv',
  'the-legend-of-heroes-trails-of-cold-steel-iv-digital-deluxe-edition',
  'r-type-final-2-stage-pass',
  'r-type-final-2-digital-deluxe-edition',
  'r-type-final-2',
  'ys-ix-monstrum-nox',
  'ys-ix-monstrum-nox-digital-deluxe-edition',
  'ys-ix-monstrum-nox-digital-ultimate-edition',
  'soul-nomad-the-world-eaters',
  'soul-nomad-and-the-world-eaters-digital-deluxe-edition',
  'soul-nomad-and-the-world-eaters-digital-art-book',
  'r-type-final-2-stage-pass-volume-2-dlc',
  'keywe-dlc-the-100-th-annual-grand-ol-telepost-tournament',
  'monark',
  'monark-digital-ultimate-edition',
  'monark-digital-deluxe-edition',
  'makai-kingdom-reclaimed-and-rebound',
  'makai-kingdom-reclaimed-and-rebound-digital-deluxe-edition',
  'zhp-unlosing-ranger-vs-darkdeath-evilman',
  'zhp-unlosing-ranger-vs-darkdeath-evilman-digital-deluxe-edition',
  'marvels-midnight-suns',
  'marvels-midnight-suns-digital-edition',
  'marvels-midnight-suns-legendary-edition',
  'marvels-midnight-suns-epic',
  'marvels-midnight-suns-digital-edition-epic',
  'marvels-midnight-suns-legendary-edition-epic',
  'disgaea-6-complete',
  'disgaea-6-complete-digital-dood-edition',
  'marvel-s-spider-man-remastered',
  'r-type-final-2-stage-pass-volume-3',
  'fallen-legion-revenants',
  'fallen-legion-revenants-digital-deluxe-edition',
  'cult-of-the-lamb',
  'la-pucelle-ragnarok',
  'la-pucelle-ragnarok-deluxe-edition',
  'rhapsody-a-musical-adventure',
  'rhapsody-a-musical-adventure-deluxe-edition',
  'hooked-on-you-a-dead-by-daylight-dating-sim',
  'nba-2-k-23',
  'nba-2-k-23-michael-jordan-edition',
  'the-legend-of-heroes-trails-from-zero',
  'kamiwaza-way-of-the-thief',
  'yomawari-lost-in-the-dark',
  'yomawari-lost-in-the-dark-deluxe-edition',
  'yomawari-series-deluxe-edition',
  'r-type-final-2-ace-pilot-special-training-pack-i',
  'r-type-final-2-ace-pilot-special-training-pack-ii',
  'r-type-final-2-ace-pilot-special-training-pack-iii',
  'warhammer-40-000-darktide',
  'warhammer-40-000-darktide-imperial-edition',
  'labyrinth-of-galleria-the-moon-society',
  'labyrinth-of-galleria-the-moon-society-deluxe-edition',
  'raiden-iv-x-mikado-remix',
  'raiden-iv-x-mikado-remix-deluxe-edition',
  'bomb-rush-cyberfunk',
  'the-legend-of-heroes-trails-to-azure',
  'lego-2-k-drive-awesome-rivals-edition-epic',
  'lego-2-k-drive-awesome-edition-epic',
  'lego-2-k-drive-epic',
  'lego-2-k-drive-awesome-rivals-edition',
  'lego-2-k-drive-awesome-edition',
  'lego-2-k-drive',
  'fairy-fencer-f-refrain-chord',
  'fairy-fencer-f-refrain-chord-deluxe-edition',
  'gylt',
  'the-legend-of-heroes-trails-into-reverie',
  'the-legend-of-heroes-trails-into-reverie-deluxe-edition',
  'the-legend-of-heroes-trails-into-reverie-ultimate-edition',
];

export default fullPriceGameExclusions;
