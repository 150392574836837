import { defineMessages } from 'react-intl';

export default {
  5: {
    min: {
      CAD: 0,
      USD: 0,
      EUR: 0,
      GBP: 0,
      AUD: 0,
      RUB: 0,
      JPY: 0,
    },
    max: {
      CAD: 600,
      USD: 500,
      EUR: 500,
      GBP: 500,
      AUD: 700,
      RUB: 25000,
      JPY: 100000,
    },
    strings: defineMessages({
      description: {
        id: 'games-under.5.description',
        defaultMessage: 'With our Games Under {priceTag} category, find all of the cheapest deals we have available in any of our sales!',
      },
    }),
  },
  10: {
    min: {
      CAD: 601,
      USD: 501,
      EUR: 501,
      GBP: 501,
      AUD: 701,
      RUB: 25001,
      JPY: 100001,
    },
    max: {
      CAD: 1200,
      USD: 1000,
      EUR: 1000,
      GBP: 1000,
      AUD: 1400,
      RUB: 50000,
      JPY: 200000,
    },
    strings: defineMessages({
      description: {
        id: 'games-under.10.description',
        defaultMessage: 'Our Games Under {priceTag} category features various great deals across the store! Check back in periodically to find new games to buy.',
      },
    }),
  },
  20: {
    min: {
      CAD: 1201,
      USD: 1001,
      EUR: 1001,
      GBP: 1001,
      AUD: 1401,
      RUB: 50001,
      JPY: 200001,
    },
    max: {
      CAD: 2400,
      USD: 2000,
      EUR: 2000,
      GBP: 2000,
      AUD: 2800,
      RUB: 100000,
      JPY: 400000,
    },
    strings: defineMessages({
      description: {
        id: 'games-under.20.description',
        defaultMessage: 'Check out our Games Under {priceTag} category, where you can find new releases and high-quality indie titles to pick from in any of our individual sales!',
      },
    }),
  },
};
