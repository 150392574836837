import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function BrandStormforce() {
  return (
    <div className="navbar-brand NavBar__brandContainer NavBar__brandContainer--stormforce">
      <Link to="/" className="NavBar__brandLogo">
        <img src="https://cdn.fanatical.com/production/brand/fanatical-logo-white.svg" alt="Fanatical" />
      </Link>
      <div className="NavBar__brandAssociation">
        <FormattedMessage id="brand-stormforce.curated-for" defaultMessage="Curated for" />
        <Link to="/">
          <img src="https://cdn.fanatical.com/production/brand/co-brands/stormforce-logo.png" alt="Stormforce" className="NavBar__brandAssociation__image" />
        </Link>
      </div>
    </div>
  );
}
