import Cookies from 'js-cookie';

const JSONParse = (stringPassed) => {
  let returnValue;
  try {
    returnValue = JSON.parse(stringPassed);
  } catch (err) {
    returnValue = null;
  }
  return returnValue;
};

export const getOne = (name) => {
  let returnValue;
  const foundValue = Cookies.get(name);
  if (typeof foundValue === 'string') {
    returnValue = JSONParse(foundValue);
  }
  return returnValue;
};

export const removeOne = name => Cookies.remove(name);

export const setOne = (name, value, options, stringify) => {
  if (typeof name !== 'string') {
    return false;
  }
  if (!value) {
    return false;
  }
  if (typeof options !== 'object') {
    return false;
  }

  let returnValue = false;

  let setValue = value;
  if (stringify) {
    try {
      setValue = JSON.stringify(value);
    } catch (err) {
      returnValue = false;
    }
  }
  returnValue = true;
  Cookies.set(name, setValue, options);
  return returnValue;
};
