import React from 'react';
import { Link } from 'react-router-dom';

export default function BrandBundlestars() {
  return (
    <div className="navbar-brand NavBar__brandContainer NavBar__brandContainer--bundlestars">
      <Link to="/" className="NavBar__brandLogo NavBar__brandLogo--bundlestars">
        <img src="https://cdn.fanatical.com/production/brand/co-brands/bundle-stars-logo-white.svg" alt="Bundle Stars" />
      </Link>
    </div>
  );
}
