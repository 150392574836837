import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import env from '../../env';
import FixedImg from '../Utility/FixedImg';
import imgixConfig from '../../config/imgix';
import useViewPort from '../../Helpers/Hooks/useViewPort';

import './SlimBanner.scss';

export default function SlimBanner({
  logoSrc,
  backgroundSrc,
  sponsorSrc,
  slimButtonColor,
  slimButtonBackgroundColor,
  slimBackgroundColor,
}) {
  const { width } = useViewPort();

  const imgWidth = null || Math.ceil(width / 100) * 100;
  const dpr = window.devicePixelRatio || 1;

  return (
    <div className="SlimBanner" style={{ backgroundColor: slimBackgroundColor }}>
      <div className="container SlimBanner__container">
        <div className="SlimBanner__content" style={{ backgroundImage: `url(${env.cdnUrl}/product/original/${backgroundSrc}?${imgixConfig}&dpr=${dpr}&h=80&w=${imgWidth})` }}>
          <div className="SlimBanner__logoButton">
            <div className="SlimBanner__saleLogo">
              <FixedImg
                src={logoSrc}
                alt="sale logo"
                lazy={false}
              />
            </div>
            <div className="SlimBanner__button" style={{ color: slimButtonColor, backgroundColor: slimButtonBackgroundColor }}>
              <FormattedMessage id="slimbanner.viewdeals" defaultMessage="View Deals" />
            </div>
          </div>
          {sponsorSrc &&
            <div className="SlimBanner__partnerLogo">
              <FixedImg
                src={sponsorSrc}
                alt="partner logo"
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

SlimBanner.propTypes = {
  logoSrc: PropTypes.string,
  backgroundSrc: PropTypes.string,
  sponsorSrc: PropTypes.string,
  slimButtonColor: PropTypes.string,
  slimButtonBackgroundColor: PropTypes.string,
  slimBackgroundColor: PropTypes.string,
};

SlimBanner.defaultProps = {
  logoSrc: '',
  backgroundSrc: '',
  sponsorSrc: '',
  slimButtonColor: '#000000',
  slimButtonBackgroundColor: '#FFFFFF',
  slimBackgroundColor: '#FFFFFF',
};
