import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import ViewportContext from './viewport-context';


const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(document.body.getBoundingClientRect().width);
  const [height, setHeight] = React.useState(document.body.getBoundingClientRect().height);

  const handleWindowResize = () => {
    setWidth(document.body.getBoundingClientRect().width);
    setHeight(document.body.getBoundingClientRect().height);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', debounce(handleWindowResize, 250, { trailing: true }));
  }, []);


  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: PropTypes.element,
};

export default ViewportProvider;
