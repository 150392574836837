import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default function NoIndex({ noFollow, allowRender }) {
  return (
    <Helmet>
      {!allowRender &&
        <meta name="prerender-status-code" content="404" />
      }
      <meta name="robots" content={`noindex${noFollow ? ', nofollow' : ''}`} />
    </Helmet>
  );
}

NoIndex.propTypes = {
  noFollow: PropTypes.bool,
  allowRender: PropTypes.bool,
};

NoIndex.defaultProps = {
  noFollow: false,
  allowRender: false,
};
