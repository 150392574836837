import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function BrandRazer() {
  return (
    <div className="navbar-brand NavBar__brandContainer NavBar__brandContainer--razer">
      <div className="NavBar__brandAssociationLarge">
        <Link to="/">
          <img src="https://cdn.fanatical.com/production/brand/co-brands/razer-logo.svg" alt="Razer" className="NavBar__brandAssociationLarge__image" />
        </Link>
      </div>
      <div className="NavBar__brandLogoSupport">
        <div className="NavBar__brandSupportText">
          <FormattedMessage id="brand-razer.curated-for" defaultMessage="Curated by" />
        </div>
        <Link to="/" className="NavBar__brandLogo">
          <img src="https://cdn.fanatical.com/production/brand/fanatical-logo-white.svg" alt="Fanatical" />
        </Link>
      </div>
    </div>
  );
}
