import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import trim from 'lodash/trim';
import { connect } from 'react-redux';

import env from '../../../env';
import AlternativeMetaTags from './AlternativeMetaTags';
import hitToListItem from '../../Product/Seo/hit-list-item';
import getUrlParameter from '../../../Helpers/Url/GetUrlParameter';

const brandTitleString = 'Fanatical';

function Meta({
  title,
  socialTitle,
  brandTitle,
  brandReverse,
  url,
  description,
  image,
  page,
  hits,
  pageType,
  nbHits,
  hitsPerPage,
  datePublished,
  dateModified,
  pageName,
}) {
  let fullUrl = null;
  let relativeUrl = null;

  // Homepage pagination links were being indexed in google, forcing the canonical back to /
  // should get them removed. If it happens to any other page types they can be excluded here
  const shouldShowPage = !['/', 'categories', 'publishers', 'franchises', 'search'].includes(url);

  if (url) {
    relativeUrl = `${url.replace(/^\//, '')}${page > 1 && shouldShowPage ? `${url && url.includes('?') ? '&' : '?'}page=${page}` : ''}`;
    fullUrl = `${env.appProtocol}://${env.appUrl}/${window.locale || 'en'}/${relativeUrl}`;
  }

  let fullImage = image;

  if (fullImage && !fullImage.includes('http')) {
    fullImage = `${env.cdnExtUrl}/${fullImage.replace(/^\//, '')}`;
  }

  if (hits && hits.length && hits.length >= 8 && !fullImage) {
    fullImage = `${env.apiProtocol}://${env.apiUrl}/image/${hits.slice(0, 8).map(h => h.cover.replace(/\./g, '_')).join('/')}`;
  } else if (hits && hits.length && hits.length >= 4 && !fullImage) {
    fullImage = `${env.apiProtocol}://${env.apiUrl}/image/${hits.slice(0, 4).map(h => h.cover.replace(/\./g, '_')).join('/')}`;
  }

  const shortDescription = truncate(trim((description || '').replace(/(<([^>]+)>)/ig, '')), { length: 155, separator: ' ' });

  const shorterDescription = truncate(trim((description || '').replace(/(<([^>]+)>)/ig, '')), { length: 125, separator: ' ' });

  const shortestDescription = truncate(trim((description || '').replace(/(<([^>]+)>)/ig, '')), { length: 65, separator: ' ' });

  const isFacetPage = relativeUrl && !!relativeUrl.match(/^(collections|franchises|publishers|categories|games-like)\//);

  const isProductPage = relativeUrl && !!relativeUrl.match(/^(game|bundle|dlc|book|comic|audio|video|software)\//);

  const isHome = url === '/';

  let hitsSchema;
  let hitsSchemaRaw;

  if (hits && hits.length) {
    let pageStart = 0;
    let numberOfItems = hits.length;

    if (nbHits) {
      numberOfItems = nbHits;
    }

    if (page && hitsPerPage) {
      pageStart = (page - 1) * hitsPerPage;
    }

    hitsSchemaRaw = {
      '@context': pageType !== 'CollectionPage' ? 'https://schema.org' : undefined,
      '@id': `${fullUrl}#itemlist`,
      '@type': 'ItemList',
      name: env.enhanceItemLists ? (socialTitle || title) : undefined,
      description: env.enhanceItemLists ? shortDescription : undefined,
      image: env.enhanceItemLists ? (fullImage || undefined) : undefined,
      numberOfItems,
      itemListElement: hits.map((h, i) => hitToListItem(h, i + pageStart, false)),
    };

    hitsSchema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(hitsSchemaRaw),
    };
  }

  const orgSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@id': 'https://www.fanatical.com/en/#organization',
      '@type': 'Organization',
      name: 'Fanatical',
      alternateName: 'Focus Multimedia Ltd.',
      url: 'https://www.fanatical.com/en/',
      logo: {
        '@type': 'ImageObject',
        '@id': 'https://www.fanatical.com/#logo',
        url: 'https://cdn.fanatical.com/production/icons/logo-200x200.png',
        caption: 'Fanatical',
      },
      parentOrganization: 'https://www.fandom.com',
      sameAs: [
        'https://www.facebook.com/wearefanatical',
        'https://twitter.com/fanatical',
        'https://www.youtube.com/wearefanatical',
        'https://en.wikipedia.org/wiki/Fanatical_(company)',
        'https://www.instagram.com/wearefanatical/',
        'https://www.crunchbase.com/organization/fanatical',
        'https://www.linkedin.com/company/wearefanatical/',
        'https://www.trustpilot.com/review/www.fanatical.com',
      ],
      foundingDate: '2012',
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'Customer Support',
        email: 'support@fanatical.com',
        url: 'https://support.fanatical.com/hc/en-us',
      },
      legalName: 'Focus Multimedia Ltd',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'The Studios, Lea Hall Enterprise Park, Wheelhouse Road',
        addressLocality: 'Rugeley',
        addressRegion: 'Staffordshire',
        addressCountry: 'UK',
        postalCode: 'WS15 1LH',
      },
    }),
  };

  const basicOrgSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@id': 'https://www.fanatical.com/en/#organization',
      '@type': 'Organization',
      name: 'Fanatical',
    }),
  };

  const siteSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@id': `https://www.fanatical.com/${window.locale}/#website`,
      '@type': 'WebSite',
      name: 'Fanatical',
      inLanguage: window.locale,
      publisher: { '@id': 'https://www.fanatical.com/en/#organization' },
      url: `https://www.fanatical.com/${window.locale}/`,
      potentialAction: isHome ? {
        '@type': 'SearchAction',
        target: `https://www.fanatical.com/${window.locale}/search?search={search_term_string}`,
        'query-input': 'required name=search_term_string',
      } : undefined,
    }),
  };

  const pageSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@id': `${fullUrl}#webpage`,
      '@type': pageType || 'WebPage',
      name: pageName || socialTitle || title,
      inLanguage: window.locale,
      description: shortDescription,
      image: fullImage || undefined,
      datePublished,
      dateModified,
      isPartOf: {
        '@id': `https://www.fanatical.com/${window.locale || 'en'}/#website`,
      },
      about: isHome ? {
        '@id': 'https://www.fanatical.com/en/#organization',
      } : undefined,
      breadcrumb: isFacetPage || isProductPage ? { '@id': `${fullUrl}#breadcrumb` } : undefined,
      mainEntity: pageType === 'CollectionPage' ? hitsSchemaRaw : undefined,
    }),
  };

  let canonicalUrl = fullUrl;

  if (canonicalUrl && canonicalUrl.includes('/blog') && window.locale) {
    canonicalUrl = canonicalUrl.replace(`/${window.locale}/`, '/en/');
  }

  return (
    <React.Fragment>
      <Helmet>
        {title &&
          <title>{`${brandReverse ? `${brandTitleString} | ` : ''}${title}${brandTitle ? ` | ${brandTitleString}` : ''}`.replace(/\s+/g, ' ')}</title>
        }
        {(socialTitle || title) &&
          <meta property="og:title" content={socialTitle || title.replace(/\s+/g, ' ')} />
        }
        {(socialTitle || title) &&
          <meta property="twitter:title" content={socialTitle || title.replace(/\s+/g, ' ')} />
        }
        {fullUrl &&
          <meta property="og:url" content={fullUrl} />
        }
        {canonicalUrl &&
          <link rel="canonical" href={canonicalUrl} />
        }
        {description &&
          <meta name="description" content={shortDescription} />
        }
        {description &&
          <meta property="og:description" content={shortestDescription} />
        }
        {description &&
          <meta property="twitter:description" content={shorterDescription} />
        }
        {fullImage &&
          <meta property="og:image" content={fullImage} />
        }
        {fullImage &&
          <meta property="twitter:image" content={fullImage} />
        }
        {fullImage &&
          <meta name="twitter:image:alt" content={title || 'Fanatical'} />
        }
      </Helmet>
      {url &&
        <Helmet script={[pageSchema]} />
      }
      {siteSchema &&
        <Helmet script={[siteSchema]} />
      }
      {isHome ?
        <Helmet script={[orgSchema]} />
        :
        <Helmet script={[basicOrgSchema]} />
      }
      {hitsSchema && pageType !== 'CollectionPage' &&
        <Helmet script={[hitsSchema]} />
      }
      {url && !relativeUrl.startsWith('blog') && !(isProductPage && page > 1) &&
        <AlternativeMetaTags path={relativeUrl} />
      }
    </React.Fragment>
  );
}

Meta.propTypes = {
  title: PropTypes.string,
  socialTitle: PropTypes.string,
  brandTitle: PropTypes.bool,
  brandReverse: PropTypes.bool,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  page: PropTypes.number,
  hits: PropTypes.arrayOf(PropTypes.shape({
    cover: PropTypes.string,
  })),
  nbHits: PropTypes.number,
  hitsPerPage: PropTypes.number,
  pageName: PropTypes.string,
  dateModified: PropTypes.string,
  datePublished: PropTypes.string,
};

Meta.defaultProps = {
  brandTitle: false,
  brandReverse: false,
  title: null,
  socialTitle: null,
  url: null,
  image: null,
  pageType: null,
  description: '',
  page: null,
  hits: [],
  nbHits: null,
  hitsPerPage: null,
  pageName: undefined,
  datePublished: undefined,
  dateModified: undefined,
};

export default connect(({ router: { location: { href } } }) => ({ page: Number(getUrlParameter('page', href) || null) }))(Meta);
