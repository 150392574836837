const env = require('../../env');

/**
 * Check if the Fanatical Extension is installed by sending a message and waiting for a response
 * @param  {Boolean} v3Only Option to only catch v3 Extension
 * @return {Promise}
 */
const checkExtensionInstalled = async (v3Only, v2Only) => new Promise((resolve, reject) => {
  const listener = (() => (event) => {
    if (
      event.data.msg === 'fan_installed' &&
      (event.data.isV3 || !v3Only) &&
      (!event.data.isV3 || !v2Only)
    ) {
      window.removeEventListener('message', listener);
      resolve();
    }
  })(); // New instance of listener to stop conflicts with concurrent calls

  if (env.isDevelopment) {
    if (window.location.search.includes('assistant_installed=1')) {
      return resolve();
    }

    if (window.location.search.includes('assistant_installed=0')) {
      return reject();
    }
  }

  // Not sure if this still works for extensions
  if (!v3Only && window.chrome && window.chrome.app && window.chrome.app.isInstalled) {
    return resolve();
  }

  setTimeout(() => {
    window.removeEventListener('message', listener);
    reject();
  }, 500);

  window.addEventListener('message', listener, false);
  window.postMessage({
    msg: 'fan_assist_install_check',
  }, '*');

  return null;
});

export default checkExtensionInstalled;
