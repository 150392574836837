import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

import { switchSiteTheme } from '../../redux/ducks/site-theme';


const FooterThemeToggle = ({ siteTheme, place, siteBrand }) => {
  const isLight = siteTheme === 'light';

  if (siteBrand !== 'fanatical') return false;

  return (
    <Button
      color="link"
      className="theme-toogle-container"
      onClick={() => switchSiteTheme(isLight ? 'dark' : 'light', place, 'button')}
    >
      <div className="d-flex">
        <div className="icon-container">
          <FontAwesomeIcon icon="lightbulb" size="lg" />
        </div>
        <div className="text-container">
          {!isLight &&
            <FormattedMessage id="theme-toggle.lighttheme" defaultMessage="Light Theme" />
          }
          {isLight &&
            <FormattedMessage id="theme-toggle.darktheme" defaultMessage="Dark Theme" />
          }
        </div>
      </div>
    </Button>
  );
};


FooterThemeToggle.propTypes = {
  siteTheme: PropTypes.string,
  place: PropTypes.string,
};

FooterThemeToggle.defaultProps = {
  siteTheme: '',
  place: '',
};

const mapStateToProps = ({
  siteTheme,
  siteBrand,
}) => ({
  siteTheme,
  siteBrand,
});


export default connect(mapStateToProps)(FooterThemeToggle);
