import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import FeedbackPopup from '../../../components/Utility/FeedbackPopups/FeedbackPopup';

import '../../../components/Utility/FeedbackPopups/FeedbackPopups.scss';

const FeedbackPopupContainer = ({ feedbackPopups }) => {
  if (isEmpty(feedbackPopups)) return false;

  return (
    <div className="popups-container">
      {feedbackPopups.map(feedback =>
        <React.Fragment key={feedback.id}>
          <FeedbackPopup feedback={feedback} />
        </React.Fragment>,
      )}
    </div>
  );
};

FeedbackPopupContainer.propTypes = {
  feedbackPopups: PropTypes.arrayOf(PropTypes.object),
};

FeedbackPopupContainer.defaultProps = {
  feedbackPopups: [],
};

const mapStateToProps = ({ feedbackPopups }) => ({ feedbackPopups });

export default connect(mapStateToProps)(FeedbackPopupContainer);
