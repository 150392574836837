import React, { useState, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

/**
 * Modal Wrapper for lazy-loading components inside the modal once the modal is opened
 * @param {Boolean}   open            is the modal open
 * @param {Function}  setOpen         function to set the modal open state
 * @param {Boolean}   hasCloseIcon    should a close icon be placed top right
 * @param {Boolean}   hasCloseFooter  should a footer with a close button be included
 * @param {Component} children        The contents of the modal, should be imported with React.lazy
 * @param {Rest}      props           Props passed to Modal component
 */
export default function LazyModal({
  open,
  setOpen,
  hasCloseIcon,
  hasCloseFooter,
  children,
  ...props
}) {
  const [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    if (open) {
      setHasOpened(true);
    }
  }, [open]);

  return (
    <Modal isOpen={open} toggle={() => setOpen(false)} {...props}>
      {hasCloseIcon &&
        <Button
          color="link"
          aria-label="Close modal"
          className="close-button"
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon="times" size="lg" title="Close modal" />
        </Button>
      }
      <ModalBody>
        {hasOpened &&
          <Suspense fallback={null}>
            {React.cloneElement(children, { open, setOpen })}
          </Suspense>
        }
      </ModalBody>
      {hasCloseFooter &&
        <ModalFooter className="modal-footer-close">
          <Button
            aria-label="Close modal"
            color="primary"
            size="lg"
            onClick={() => setOpen(false)}
          >
            <FormattedMessage id="lazy-modal.close-button" defaultMessage="Close" />
          </Button>
        </ModalFooter>
      }
    </Modal>
  );
}

LazyModal.propTypes = {
  open: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  hasCloseFooter: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.element.isRequired,
};

LazyModal.defaultProps = {
  open: false,
  hasCloseIcon: true,
  hasCloseFooter: false,
  setOpen: () => {},
};
