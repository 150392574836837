import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/difference_in_minutes';

export default function searchOrderItems(orders, searchTerm) {
  const orderItemSearchString = (items) => {
    const itemsString = items.map(item => item.name).toString().toLowerCase();
    const noSpecialCharacters = itemsString.replace(/[^a-zA-Z ]/g, ' ').toLowerCase();
    return `${noSpecialCharacters} ${itemsString}`;
  };

  const filteredOrder = orders.filter((order) => {
    let updatedOrder = {};
    const currentDateTime = new Date();
    updatedOrder =
      ((['PENDING', 'INITIALISED'].includes(order.status) && differenceInMinutes(`${format(currentDateTime, 'YYYY-MM-DDTHH:mm:ss')}Z`, order.date) < 60)
      || !['PENDING', 'INITIALISED'].includes(order.status));
    return updatedOrder;
  });

  return filteredOrder.filter((order) => {
    const orderItems = [];
    order.items.forEach((item) => {
      orderItems.push(item);
      if (item.bundles.length) {
        item.bundles.forEach((bundle) => {
          bundle.games.forEach((game) => {
            orderItems.push(game);
          });
        });
      }
    });
    const orderSearchTerm = orderItemSearchString(orderItems);
    return orderSearchTerm.includes(searchTerm);
  });
}
