import get from 'lodash/get';

import store from '../../redux/store';

/**
 * Get and parse user traits from local storage
 * @return {Object|Null}
 */
export const getUserTraits = () => store.getState().userTraits;

/**
 * Is the customer a bulker with a significant amount of order items / orders
 * @return {Boolean}
 */
export const isBulker = () => {
  const traits = getUserTraits();
  return get(traits, 'c_type') === 'b' && (get(traits, 'orders') > 150 || get(traits, 'order_items') > 700);
};

export const lastUsedPayment = () => {
  const traits = getUserTraits();
  return get(traits, 'last_payment', null);
};
