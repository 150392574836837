// Language config
const env = require('../env');

const enabledLanguages = env.enabledLanguages;

const isEnabled = (code) => {
  if (!enabledLanguages.length) {
    return true;
  }
  if (enabledLanguages.includes(code)) {
    return true;
  }
  return false;
};

const isSeoEnabled = code => isEnabled(code); // Separate list not used any more

const languages = [
  {
    code: 'en',
    label: 'English',
    nativeLabel: 'English',
    default: true,
  },
  {
    code: 'de',
    label: 'German',
    filename: 'de-DE',
    nativeLabel: 'Deutsch',
  },
  {
    code: 'es',
    label: 'Spanish',
    filename: 'es-ES',
    nativeLabel: 'Español',
  },
  {
    code: 'fr',
    label: 'French',
    filename: 'fr-FR',
    nativeLabel: 'Français',
  },
  {
    code: 'it',
    label: 'Italian',
    filename: 'it-IT',
    nativeLabel: 'Italiano',
  },
  {
    code: 'ja',
    label: 'Japanese',
    filename: 'ja-JP',
    nativeLabel: '日本語',
    font: 'https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&subset=japanese&display=swap',
  },
  {
    code: 'ko',
    label: 'Korean',
    filename: 'ko-KR',
    nativeLabel: '한국어',
    font: 'https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700&subset=korean&display=swap',
  },
  {
    code: 'nl',
    label: 'Dutch',
    filename: 'nl-NL',
    nativeLabel: 'Nederlands',
  },
  {
    code: 'pl',
    label: 'Polish',
    filename: 'pl-PL',
    nativeLabel: 'Polski',
    font: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&amp;subset=latin-ext&display=swap',
  },
  {
    code: 'pt',
    label: 'Portuguese',
    filename: 'pt-PT',
    nativeLabel: 'Português',
  },
  {
    code: 'ru',
    label: 'Russian',
    filename: 'ru-RU',
    nativeLabel: 'Русский',
    font: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&amp;subset=cyrillic-ext,latin-ext&display=swap',
  },
  {
    code: 'zh-hans',
    label: 'Chinese (Simplified)',
    filename: 'zh-CN',
    alternative: 'zh-cn',
    nativeLabel: '简体中文',
    font: 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;700&display=swap',
  },
  {
    code: 'zh-hant',
    label: 'Chinese (Traditional)',
    filename: 'zh-TW',
    alternative: 'zh-tw',
    nativeLabel: '繁體中文',
    font: 'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;700&display=swap',
  },
];

const getAll = () => languages.filter(lang => isEnabled(lang.code));
const getAllSeo = () => languages.filter(lang => isSeoEnabled(lang.code));

const getAsObject = () => {
  const obj = {};
  getAll().forEach((lang) => {
    obj[lang.code] = Object.assign({}, lang);
    delete obj[lang.code].code;
  });
  return obj;
};

const getByCode = code =>
  getAll().find(lang => lang.code === code || (lang.alternative && lang.alternative === code));

const publicFormat = language => ({
  code: language.code,
  label: language.label,
  nativeLabel: language.nativeLabel,
});

const getDefault = () => languages.filter(lang => lang.default)[0];

/**
 * Find first match of browser's languages for one of our supported languages
 * @return {String|Null} language code if found, or null
 */
const findBrowserLang = () => {
  const userLanguages = navigator.languages || [navigator.language] || ['en-US'];

  const supportedLanguages = getAll();
  let matchedLang = null;

  const comparisons = [
    (code, lang) => code && code === lang.code,
    (code, lang) => code && code.substr(0, 2) === lang.code,
  ];

  userLanguages.some(code => supportedLanguages.some(lang => comparisons.some((comparison) => {
    if (!matchedLang && comparison(code, lang)) {
      matchedLang = lang.code;
      return true;
    }
    return false;
  })));

  return matchedLang;
};

const getSupportCode = (code) => {
  if (getByCode(code)) {
    return getByCode(code).alternative || code;
  }
  return code;
};

const supportUrl = () => `${env.supportUrl}/${getSupportCode(window.locale || env.defaultLanguage)}`;

module.exports = {
  getAll,
  getAllSeo,
  getAsObject,
  getByCode,
  publicFormat,
  getDefault,
  findBrowserLang,
  supportUrl,
};
