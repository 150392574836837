import React from 'react';
import { Link } from 'react-router-dom';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavLink from 'reactstrap/lib/NavLink';

export default function BrandFanatical() {
  return (
    <NavbarBrand tag="div">
      <NavLink aria-label="homepage, graphic, Fanatical logo" tag={Link} to="/" />
    </NavbarBrand>
  );
}
