const functions = {};
let waitingForActive = false;

/**
 * Event listener for calling all queued functions when window becomes active again
 */
const onVisibilityChange = () => {
  if (!document.hidden) {
    document.removeEventListener('visibilitychange', onVisibilityChange, false);
    Object.keys(functions).forEach((k) => {
      functions[k]();
    });
    setTimeout(() => { waitingForActive = false; }, 5000);
  }
};

/**
 * If the window is visible, call a function immediately, otherwise
 * queue the function until the window is activated again and call (once) then
 * @param  {String}   key      Identifier to limit each function to one call
 * @param  {Function} callback Function to call
 */
export default function waitForActive(key, callback) {
  if (typeof key !== 'string' || !key) {
    throw new Error('Key must be a string');
  }

  if (typeof callback !== 'function') {
    throw new Error('Callback must be a function');
  }

  if (document.hidden) {
    if (!waitingForActive) {
      waitingForActive = true;
      document.addEventListener('visibilitychange', onVisibilityChange, false);
    }
    functions[key] = callback;
  } else {
    callback();
  }
}
