import React from 'react';
import PropTypes from 'prop-types';

import { currencyCode } from '../../Helpers/Geo/data';
import Price from '../../containers/Utility/Price/Price';
import SpecialFormattedNumber from '../../containers/Utility/Price/SpecialFormattedNumber';

export default function CheckoutWasPrice({ item, freeProduct }) {
  /* eslint-disable react/style-prop-object */
  if (freeProduct) {
    if (item.price[currencyCode] === 0) return false;
    return (
      <div className="was-price-container">
        <Price
          price={item.price}
        />
      </div>
    );
  }

  if (item.pickAndMix) {
    return (
      <div className="was-price-container">
        {item.type === 'bundle' ? (
          <Price
            price={item.fullPrice}
          />
        ) : (
          <Price
            price={item.price}
          />
        )}
      </div>
    );
  }

  if (item.out && item.out.isStarDeal) {
    return (
      <div className="was-price-container">
        <SpecialFormattedNumber
          value={item.out.price / 100}
          style="currency"
        />
      </div>
    );
  }

  if (item.out && item.out.couponPrice > 0 && item.type === 'bundle' && item.mystery) {
    return (
      <div className="was-price-container">
        <SpecialFormattedNumber
          value={(item.price[currencyCode] / 100) * item.quantity}
          style="currency"
          currency={currencyCode}
        />
      </div>
    );
  }

  if (item.type === 'bundle') {
    if (
      !item.fullPrice ||
      !item.fullPrice[currencyCode] ||
      item.fullPrice[currencyCode] === item.price[currencyCode]
    ) {
      return false;
    }
    return (
      <div className="was-price-container">
        <SpecialFormattedNumber
          value={(item.fullPrice[currencyCode] / 100) * item.quantity}
          style="currency"
          currency={currencyCode}
        />
      </div>
    );
  }

  if (item.out && item.out.couponPrice > 0) {
    return (
      <div className="was-price-container">
        <SpecialFormattedNumber
          value={(item.price[currencyCode] / 100) * item.quantity}
          style="currency"
          currency={currencyCode}
        />
      </div>
    );
  }


  if (item.current_discount && item.current_discount.display_percentage) {
    return (
      <div className="was-price-container">
        <Price
          price={item.price}
        />
      </div>
    );
  }

  return false;
}

CheckoutWasPrice.propTypes = {
  item: PropTypes.shape({}).isRequired,
  freeProduct: PropTypes.bool,
};

CheckoutWasPrice.defaultProps = {
  freeProduct: false,
};
